import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  DependencyList,
} from "react";

// Determine whether to use useEffect or useLayoutEffect based on the environment
export const useEnhancedEffect =
  typeof window === "undefined" ? useEffect : useLayoutEffect;

// Define the type for the callback function
type CallbackFunction<T extends any[]> = (...args: T) => void;

// Define the type for the useEventCallback hook
const useEventCallback = <T extends any[]>(fn: CallbackFunction<T>) => {
  const callbackRef = useRef<CallbackFunction<T>>(fn);

  useEnhancedEffect(() => {
    callbackRef.current = fn;
  });

  return useCallback((...args: T) => callbackRef.current(...args), []);
};

export default useEventCallback;
