type DebouncedFunction<T extends (...args: any[]) => any> = {
  (...args: Parameters<T>): void;
  clear(): void;
};

export default function debounce<T extends (...args: any[]) => any>(
  func: T,
  wait: number = 166
): DebouncedFunction<T> {
  let timeout: ReturnType<typeof setTimeout>;

  function debounced(this: any, ...args: Parameters<T>): void {
    const later = () => {
      func.apply(this, args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  }

  debounced.clear = (): void => {
    clearTimeout(timeout);
  };

  return debounced as DebouncedFunction<T>;
}
