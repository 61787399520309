import React from "react";

interface TabProps {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  selected?: boolean;
  tabAs?: keyof JSX.IntrinsicElements;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  [key: string]: any; // For any additional props
}

const Tab = React.forwardRef<HTMLDivElement, TabProps>(
  (
    {
      className = "",
      selected,
      style,
      tabAs = "button",
      onTabClick,
      ...props
    }: TabProps,
    ref
  ) => {
    const Tag = tabAs as React.ElementType;

    const buttonSpecialProps = {
      type: "button",
    };

    const tabClassNames = `${className} ${
      selected ? "rts___tab___selected" : ""
    }`.trim();

    const handleClick = (e: React.MouseEvent<HTMLElement>) => {
      if (onTabClick) {
        onTabClick(e);
      }
      if (props?.onClick) {
        (props.onClick as (event: React.MouseEvent<HTMLElement>) => void)(e);
      }
    };

    return (
      <Tag
        onClick={handleClick}
        // {...props}
        className={tabClassNames}
        ref={ref as React.RefObject<HTMLDivElement>}
        style={style}
        {...(tabAs === "button" ? buttonSpecialProps : {})}
      >
        {props.children}
      </Tag>
    );
  }
);

export default Tab;
